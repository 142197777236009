.pagination *[class^="btn-"] a {
  margin: 0 4px;
  border: 1px solid transparent;
  border-radius: 50%;
  color: $dms-gray-300;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
  transition: all 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pagination *[class^="btn-"] a:hover {
  background: $dms-green;
  color: $white;
}

.pagination *[class^="btn-"].active a{
  background: $dms-green-700;
  color: $white;
  border-color: $dms-green-700
}