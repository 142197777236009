.dashboard-wrapper {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 250px calc(50% - 141px) calc(50% - 141px);
  grid-template-rows: 210px 150px 460px;
  height: 100%;
  width: 100%;
  @include media-breakpoint-down(lg) {
    grid-template-columns: 250px calc(100% - 266px);
    grid-template-rows: 210px 214px 220px 550px 1fr;
  }
  & > .wallet-balance {
    grid-area: 1 / 1 / 4 / 2;
    @include media-breakpoint-down(lg) {
      grid-area: 1 / 1 / 4 / 2;
    }
  }
  & > .services {
    grid-area: 1 / 2 / 2 / 3;
    @include media-breakpoint-down(lg) {
      grid-area: 1 / 2 / 2 / 3;
    }
  }
  & > .main-banner {
    grid-area: 1 / 3 / 2 / 4;
    @include media-breakpoint-down(lg) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
  & > .mini-banner {
    grid-area: 2 / 3 / 3 / 4;
    @include media-breakpoint-down(lg) {
      grid-area: 3 / 2 / 4 / 3;
    }
  }
  & > .daily-transactions {
    grid-area: 2 / 2 / 4 / 3;
    @include media-breakpoint-down(lg) {
      grid-area: 4 / 1 / 5 / 3;
    }
  }
  & > .transactions-history {
    grid-area: 3 / 3 / 4 / 4;
    @include media-breakpoint-down(lg) {
      grid-area: 5 / 1 / 6 / 3;
    }
  }
}

.dashboard-wrapper-ba {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 250px calc(50% - 141px) calc(50% - 141px);
  grid-template-rows: 500px 150px 460px;
  height: 100%;
  width: 100%;
  @include media-breakpoint-down(lg) {
    grid-template-columns: 250px calc(100% - 266px);
    grid-template-rows: 280px 220px 150px 550px 1fr;
  }
  & > .wallet-balance {
    grid-area: 1 / 1 / 4 / 2;
    @include media-breakpoint-down(lg) {
      grid-area: 1 / 1 / 4 / 2;
    }
  }
  & > .sales {
    grid-area: 1 / 2 / 2 / 3;
    @include media-breakpoint-down(lg) {
      grid-area: 4 / 1 / 4 / 3;
    }
  }
  & > .retail-area {
    grid-area: 2 / 2 / 4 / 3;
    @include media-breakpoint-down(lg) {
      grid-area: 5 / 1 / 5 / 3;
    }
  }
  & > .daily-transactions {
    grid-area: 1 / 3 / 5 / 4;
    @include media-breakpoint-down(lg) {
      grid-area: 1 / 2 / 3 / 3;
    }
  }
  & > .top-frontliner {
    grid-area: 2 / 3 / 4 / 3;
    @include media-breakpoint-down(lg) {
      grid-area: 6 / 1 / 6 / 3;
    }
  }
}
