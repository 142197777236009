.map-center-marker{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
  display: block;
  height: calc(130px / 2.5);
  width: calc(110px / 2.5);
  background-size: contain;
  background-repeat: no-repeat;
}

.map-wrapper {
  position: relative;
  margin-top: -400px;
  height: 400px;
  pointer-events: none;
}

.map-wrapper-form {
  .row:first-child {
    display: none;
  }

  .row:last-child {
    margin-top: 12px;
  }
}

.map-overlay{
  position: absolute;
  background: #fffd;
  top: 34px;
  left: 0px;
  right: 0px;
  height: 365px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  padding-top: 33%;
  text-align: center;
}