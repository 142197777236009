.card {
  border: none;

  &-bordered {
    margin: 0 auto;
    border-top: 3px solid $color-primary;
    border-radius: 0;
    padding: 2em;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.15);

    &-content {
      max-width: 700px;

      .card-body {
        max-height: calc(100vh - 500px);
        overflow-x: scroll;
      }
    }

    &-form {
      max-width: 550px;
    }

    .card-footer {
      margin-top: 2em;
      padding: 0;
      border: 0;
      background-color: inherit;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        min-width: 170px;
      }
    }
  }

  &-filled {
    max-width: 700px;
    margin: 0 auto;
    border-radius: 1em;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.15);

    &-content {
      max-width: 100%;
    }

    .card-header {
      border-radius: 1em 1em 0 0;
      background-color: $color-primary;
    }

    .card-body {
      padding: 1.75em 1.75em 0 1.75em;
      background-color: $white;
    }

    .card-footer {
      padding: 1em 1.75em 1.75em 1.75em;
      border-radius: 0 0 1em 1em;
      border: 0;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-offer {
    box-shadow: 0px 8px 15px rgba($dms-gray-300, 0.4);
    border-radius: 8px;
    padding: 24px 16px 16px;
    text-decoration: none;
    transition: 500ms ease all;
    &.tnt {
      .promo-title >  .title {
        color: $dms-tnt;
      }
      &:hover {
        background: $dms-tnt;
      }
    }
    &.smartbro {
      .promo-title >  .title {
        color: $dms-smartbro;
      }
      &:hover {
        background: $dms-smartbro;
      }
    }
     &.pldt {
      .promo-title >  .title {
        color: $dms-pldt;
      }
      &:hover {
        background: $dms-pldt;
      }
    }
    &.cignal {
      .promo-title >  .title {
        color: $dms-cignal;
      }
      &:hover {
        background: $dms-cignal;
      }
    }
    &.meralco {
      .promo-title >  .title {
        color: $dms-meralco;
      }
      &:hover {
        background: $dms-meralco;
      }
    }
    &.smart {
      .promo-title >  .title {
        color: $dms-green;
      }
      &:hover {
        background: $dms-green;
      }
    }

    .promo-logo {
      transition: 500ms ease all;
    }
    .promo-title >  .title {
      color: $dms-green;
      transition: 500ms ease all;
    }
    .promo-description,
    .promo-price {
      color: $black;
      transition: 500ms ease all;
    }
    .promo-valid {
      color: $dms-gray-300;
      transition: 500ms ease all;
    }
    .promo-title,
    .promo-description,
    .promo-valid,
    .promo-price {
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
      background: $dms-green;
      color: $white;
      box-shadow: 0px 8px 15px rgba($dms-gray-300, 0.4);
      .promo-title >  .title,
      .promo-description,
      .promo-price,
      .promo-valid {
        color: $white;
      }
    }
  }
}

.top-card-details {
  .card-title {
    color: $color-secondary;
    font-size: 32px;
  }
  .card-subtitle {
    color: $color-secondary;
    font-size: 16px;
  }
  .card-text {
    font-size: 13px;
    color: #9fa0a1;
  }
}

.top-card-image {
  .top-image {
    height: 100px;
    width: auto;
  }
}

.navbar-name {
  font-size: 14px;
}

.card-columns {
  @include media-breakpoint-down(md) {
    column-count: 3;
    column-gap: 24px;
  }
  @include media-breakpoint-down(lg) {
    column-count: 3;
    column-gap: 16px;
  }
  @include media-breakpoint-down(xl) {
    column-count: 4;
    column-gap: 24px;
  }
}

.card-custom-top {
  .card-body {
    height: 210px;
  }
}

.card-transaction {
  .card-body {
    height: 480px;
  }
}