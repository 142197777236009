.ads-banner {
	border-radius: 4px;
	position: relative;
	overflow: hidden;
	width: 100%;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	object-fit: cover;
	height: 210px;

	&-mini {
		border-radius: 4px;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
		height: 200px;
		object-fit: cover;
		width: 400px;
	}

	&-mini-2 {
		border-radius: 4px;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
		object-fit: cover;
		height: 150px;
	}
}