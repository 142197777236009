.legends-badge {
	.circle {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		margin-right: 8px;
		display: inline-block;
		vertical-align: middle;
	}
	.text {
		font-size: 16px;
		line-height: 24px;
		display: inline-block;
		vertical-align: middle;
	}
	&.kita {
		.circle {
			background: rgba(225, 225, 225, 1);
		}
	}
	&.benta {
		.circle {
			background: rgba(45, 190, 96, 1);
		}
	}
}