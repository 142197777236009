// Color Palette
// examples based on the official Sass styleguide

// descriptive colors
// =============
$apple: #60bb45;
$mantis: #69c45d;
$alizarin-crimson: #e5311c;
$totem-pole: #393f44;
$bright-sun: #ffda46;
$jacarta: #28296c;
$cadet-blue: #b0bac9;
$mystic: #e3e7ef;
$patina: #699;
$nebula: #d2e1dd;
$white: #fff;
$wild-sand: #f5f5f5;
$dawn-pink: #f2ece4;
$wafer: #e1d7d2;
$iron: #dadbdf;
$regent-grey: #808c99;
$pale-sky: #6b717f;
$midnight-blue: #036;
$black: #000;
$mine-shaft: #313131;
$alto: #e3e7ef;

$dms-green: #2dbe60;
$dms-green-700: #00a259;
$dms-red: #e5311c;
$dms-gray-700: #4a4a4a;
$dms-gray-500: #6e6e6e;
$dms-gray-400: #818181;
$dms-gray-300: #949494;
$dms-gray-100: #dddddd;

// main color palette
// =============
$color-menu: $apple;
$color-menu-active: $apple;
$color-primary: $dms-green;
$color-secondary: $dms-red;
$card-dark-bg: $mystic;
$color-accent: $patina;
$color-shadow: rgba($black, 0.125);
$color-note: #666;
$color-label: $cadet-blue;
$color-requirements: $alto;

// Common colors
$color-background: $white;
$color-background-shade: $iron;
$color-background-invert: $bright-sun;
$color-text: $dms-gray-700;
$color-text-weak: $regent-grey;
$color-text-strong: $midnight-blue;
$color-text-heading: $regent-grey;
$color-text-invert: rgba($color-background, 0.75);
$color-text-strong-invert: $color-background;

// Links
$color-link: $color-secondary;
$color-link-hover: darken($color-secondary, 10%);
$color-link-visited: lighten($color-secondary, 10%);

// Code
$color-code: #333;
$color-code-background: #f3f3f3;
$color-pre: #d4d4d4;
$color-pre-background: #333;

// Selections
$color-selection: #b3d4fc;

// Messages
$color-message: #f4ecbb;
$color-success: #00ff9c;
$color-warning: #cf8600;
$color-important: #d00;
$color-notice: #66b;
$color-info: #1a9fe6;
$color-danger: #d00;

$dms-tnt: #f06225;
$dms-smartbro: #3fa6d5;
$dms-pldt: #d22030;
$dms-cignal: #ff0a0a;
$dms-meralco: #fc7019;

// Shades of Gray
$gray-900: #333333;
$gray-800: #4b4b4b;
$gray-700: #636363;
$gray-600: #7b7b7b;
$gray-500: #939393;
$gray-400: #aaaaaa;
$gray-300: #dddddd;
$gray-200: #e9e9e9;
$gray-100: #f7f7f7;

//alerts
$alerts-success: #34e400;
$alerts-critical: #ff0000;
$alerts-warning: #ffd339;
