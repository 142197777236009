.file-upload {
  border: 2px dashed $dms-gray-300;
  border-radius: 1em;
  padding: 1em;

  &:hover {
    border-color: $dms-green;
  }

  &-btn {
    cursor: pointer;
    border-radius: 0.5em;
    padding: 0.75em;

    &:active,
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($dms-green, 0.25);
      border: 1px solid lighten($dms-green, 30%);
    }
  }

  .label,
  .caption {
    color: $dms-gray-300;
  }

  .label {
    width: 90%;
    margin-bottom: 1em;
  }

  .caption {
    color: $dms-green;
    font-weight: 500;
  }
}
