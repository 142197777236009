.empty-state {
  margin: 0 auto;
}

.no-data-found {
  font-size: 13px;
}

.application-empty {
  margin: 0 auto;
}
