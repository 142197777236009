.services-item {
 text-align: center;
 text-decoration: none;
 display: flex;
 align-items: center;
 flex-direction: column;
 .services-icon {
	 border-radius: 50%;
	 transition: 500ms ease;
 }
 .text {
	 color: $dms-gray-700;
	 text-align: center;
	 font-weight: 600;
	 text-decoration: none;
 }
 &:hover {
	 text-decoration: none;
	 .services-icon {
		 transform: translateY(-10px) scale(1.1);
	 }
	 .text {
		 color: $dms-green;
	 }
 }
}