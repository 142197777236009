.badge {
  text-transform: uppercase;
}
.badge-success {
  background-color: lighten($color-success, 45%);
  color: darken($color-success, 20%);
}
.badge-info {
  background-color: lighten($color-info, 45%);
  color: darken($color-info, 20%);
}
.badge-warning {
  background-color: lighten($color-warning, 48%);
  color: $color-warning;
}
.badge-danger {
  background-color: lighten($color-danger, 52%);
  color: darken($color-danger, 10%);
}
.badge-secondary {
  background-color: lighten($dark, 74%);
  color: lighten($dark, 34%);
}

.badge-clear-success {
  background-color: #fff0;
  color: $alerts-success;
  text-transform: capitalize !important;
  line-height: 12px;
  font-size: 1rem;
  font-weight: bold;
}

.badge-clear-danger {
  background-color: #fff0;
  color: $alerts-critical;
  text-transform: capitalize !important;
  line-height: 12px;
  font-size: 1rem;
  font-weight: bold;
}

.badge-clear-warning {
  background-color: #fff0;
  color: $alerts-warning;
  text-transform: capitalize !important;
  line-height: 12px;
  font-size: 1rem;
  font-weight: bold;
}
