.list-group {
  &-listing {
    .list-group-item {
      padding: 1.5em 2em;
      border-left-color: transparent;
      border-right-color: transparent;
      border-radius: 0;

      &.active {
        background-color: $dms-gray-100;
        color: inherit;
        font-weight: 500;
        border: none;
        border-right: 5px solid $color-primary;
      }
    }
  }
}
