@import '../../utilities/colors';

$out-of-range-color: lighten($dms-gray-700, 30%) !default;
$out-of-range-bg-color: lighten($dms-gray-700, 60%) !default;

$calendar-border: rgba($dms-gray-100, 0.5) !default;
$cell-border: rgba($dms-gray-100, 0.5) !default;

$border-color: $dms-gray-100 !default;

$segment-width: percentage(1 / 7) !default;

$time-selection-color: white !default;
$time-selection-bg-color: rgba(0,0,0, .50) !default;
$date-selection-bg-color: rgba(0,0,0, .10) !default;


$event-bg: $dms-green !default;
$event-border: rgba($dms-green, 0.1) !default;
$event-outline: lighten($dms-green, 10%) !default;
$event-color: #fff !default;
$event-border-radius: 4px !default;
$event-padding: 8px 8px !default;
$event-zindex: 4 !default;

$btn-color: #373a3c !default;
$btn-bg: #fff !default;
$btn-border: #ccc !default;

$current-time-color: #74ad31 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: rgba($dms-green, 0.05) !default;