.login-wrapper {
  height: 100%;
  width: 100%;
  background: url("../../images/login-box.svg") no-repeat center right;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-container {
    max-width: 380px;
    margin-right: 4rem;
  }
}

.logo-operated-by {
  position: absolute;
  right: 40px;
  bottom: 30px;
}
