.btn {
  font-weight: bold;
  text-transform: uppercase;
  &.btn-custom {
    padding: 8px 16px;
    height: 40px;
  }
  &-default {
    background-color: transparent;
    border: 1px solid transparent;
    color: $dms-gray-300;

    &-primary {
      color: $dms-green;
      font-size: 0.85em;
      padding: 0.25em;
    }
  }

  &-menu-link {
    font-weight: normal;
    text-transform: initial;
    text-align: left;
    border-radius: 0;
    appearance: none;

    &:focus {
      box-shadow: initial;
    }
  }
}

.input-group-btn {
  height: 35px;
  width: 46px;
  .btn {
    height: 35px;
    width: 46px;
    padding: 5px;
    border-radius: 0 4px 4px 0;
  }
}

.btn-delete {
  color: #ffffff;
  background-color: #f33e35;
  border-color: #f33e35;
}

.btn-update {
  color: #ffffff;
  background-color: #0095d2;
  border-color: #0095d2;
}

.btn-yes {
  color: #ffffff;
  background-color: #009838;
  border-color: #009838;
}

.btn-no {
  color: #ffffff;
  background-color: #818181;
  border-color: #818181;
}

.btn-modal-yes {
  color: #ffffff;
  background-color: #009838;
  border-color: #009838;
}

.btn-modal-no {
  color: #ffffff;
  background-color: #818181;
  border-color: #818181;
}

.btn-download {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-blue {
  color: #ffffff;
  background-color: #00a6d7;
  border-color: #00a6d7;
}

.btn-gray {
  background-color: $gray-500;
  border-color: $gray-500;
  transition: 0.2s ease-in-out;
  color: $white;
  font-weight: 600;
  &:hover {
    background-color: $gray-700;
    border-color: $gray-700;
    transition: 0.2s ease-in-out;
  }
}

.btn-signin {
  height: 48px;
  padding: 8px 16px;
  font-weight: 700;
  background: #3db06d;
  border-radius: 5px;
  font-size: 16px;
  color: #fafafa;
  letter-spacing: 2px;
  text-align: center;
  line-height: 32px;
  border: none;
  transition: 500ms ease all;
}

.btn-helpdesk {
  border-width: 1px;
  border-style: solid;
  border-color: $dms-gray-100;
  border-radius: 6px;
  background-color: $white;
  transition: all 300ms ease;

  @include hover {
    border-color: $color-primary;
    @extend .shadow;
  }
}

.btn-main-menu {
  padding: 16px;
  text-align: left;
  font-size: 14px;
  border-radius: 0;
}

.btn-ellipsis {
  .circle {
    width: 8px;
    height: 8px;
    background: $dms-green;
    margin: 0 2px 0;
    border-radius: 50%;
    display: inline-block;
  }
}

.btn {
  &.btn-date {
    height: 40px;
    padding: 8px;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    border-color: $dms-gray-700;
    background: transparent;
    color: $dms-green-700;
    &:hover {
      color: $dms-green;
    }
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      outline: 0;
      box-shadow: 0 !important;
      background: transparent !important;
      border-color: $dms-green-700 !important;
      color: $dms-green-700 !important;
    }
  }
}

.btn-blue {
  color: #ffffff;
  background-color: #3e64ff;
  border-color: #3e64ff;
  &:focus {
    box-shadow: none;
  }
}

.btn > .spinner-border {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.security-btn {
  color: black;
  background-color: #d6d6d6;
  outline: none;
  width: 14rem !important;
  height: 3.5rem;
}
.security-btn:hover,
.security-btn:focus {
  color: white;
  background-color: green;
  outline: none;
}

.forgot-btn {
  color: #212529;
  background-color: #d6d6d6;
  outline: none;
  width: 10rem !important;
  height: 3rem;
}
.forgot-btn:hover,
.forgot-btn:focus {
  color: white;
  background-color: green;
  outline: none;
}

.tabs-container {
  border-bottom: 3px solid rgb(120, 120, 120) !important;
  background: #ecedf2 !important;

  & *:first-child {
    border-top-left-radius: 7px !important;
  }

  & *:last-child {
    border-top-right-radius: 7px !important;
  }
}

.tabs__container-link {
  transition: all 0.5s;
  &.active {
    color: #fff !important;
    background: rgb(120, 120, 120) !important;
    &:hover {
      color: #fff !important;
    }
  }
  &:hover {
    color: rgb(120, 120, 120) !important;
  }
}

.tabs__container-button {
  border: none;
  transition: all 0.5s;
  color: rgb(120, 120, 120);
  background: rgb(235, 235, 235);
  &.active {
    color: #fff !important;
    background: rgb(120, 120, 120) !important;
    &:hover {
      color: #fff !important;
    }
  }
  &:hover {
    /* color: #fff!important;
    background: rgb(120, 120, 120)!important;*/
    color: rgb(120, 120, 120) !important;
  }
  &:focus {
    outline: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-green {
  border: 2px solid #28a745;
  border-radius: 0.5rem;
  background: transparent;
  color: #28a745;
  font-size: 12px;
  font-weight: bold;
  padding: 0.5rem;
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    box-shadow: none;
  }
  &:disabled {
    background: #ccc;
    color: white;
    border-color: #b7b7b7;
  }
}

.btn-green-filled {
  border: 2px solid $dms-green;
  border-radius: 0.5rem;
  background: $dms-green;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 0.5rem;
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    box-shadow: none;
  }
}

.no-outline-btn {
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    box-shadow: none;
  }
}

.btn-sm {
  padding: 4px 16px;
  .text {
    font-size: 14px;
    line-height: 16px;
  }
  &.btn-icon {
    padding: 4px;
  }
}
