.image-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.image-container figure {
  background: rgb(0, 0, 0, 0.75);
  text-align: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.with-image,
.no-image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.with-image img {
  opacity: 1;
  -webkit-transform: scale(1.5);
  -webkit-filter: grayscale(0) blur(0);
  filter: grayscale(0) blur(0);
  transform: scale(1.5);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.with-image:hover img {
  -webkit-transform: scale(1);
  -webkit-filter: grayscale(100%) blur(3px);
  filter: grayscale(50%) blur(3px);
  transform: scale(1);
  opacity: 0.5;
}

.middle {
  transition: 0.2s ease;
  opacity: 0;
  position: absolute;
  top: calc(50% - 13.5px);
  text-align: center;
}

.image-container:hover .middle {
  opacity: 1;
}

.image-text {
  color: white;
  font-weight: 800;
  font-size: 18px;
}

.default-view {
  height: 100%;
}

.default-view,
.with-image {
  cursor: zoom-in;
}
.no-image {
  cursor: default;
}
