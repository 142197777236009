.avatar {
  height: auto;
  border-radius: 50%;
  border: 1px solid $dms-gray-100;

  &-md {
    width: 48px;
  }

  &-sm {
    width: 32px;
  }

  &-xs {
    width: 24px;
  }
}

.user-avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: $white;
  box-shadow: 0px 8px 6px rgba($black, 0.10);
  padding: 10px;
  overflow: hidden;
  position: relative;
  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
