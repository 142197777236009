.ctrl-panel a {
  background: #bbb1b1;
  border-radius: 2rem;
  margin-right: 0.75rem;
}

.control-panel div a {
  color: grey;
  &:hover {
    color: green;
  }
}

.container-max {
  max-height: 100%;
  max-width: 100%;
  min-width: 100%;
}

.min-img {
  padding-top: 4.5rem;
  padding-bottom: 0.75rem;
  min-height: 100vh;
  align-items: center;
}

.react-transform-component {
  margin: 0 auto;
}

.react-transform-element {
  height: 90vh !important;
  img {
    max-height: 100%;
    min-height: 100%;
  }
}

.auth-image {
  .default-view {
    height: 80px;
    width: 80px;
    border: 1px solid #8080802e;
    img {
      min-height: 100%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
    }
  }
}
