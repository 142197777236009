input[type='checkbox'].toggle-switch {
  font-size: 30px;
  appearance: none;
  width: 48px;
  height: 24px;
  background: $iron;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].toggle-switch:checked {
  background: $dms-green;
}

input[type='checkbox'].toggle-switch:after {
  position: absolute;
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  transform: scale(0.7);
  left: 0;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].toggle-switch:checked:after {
  left: calc(100% - 24px);
}

input[type='checkbox'].large-switch {
  width: 5.5rem !important;
  height: 2.5rem !important;
}

.large-switch:after {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.large-switch:checked:after {
  left: calc(100% - 2.5rem) !important;
}
