.note {
  background-color: #edf5f2;
  font-size: 0.9vw;
  text-align: center;
  padding: 15px 8px;
}

.upload-requirements {
  color: grey;
  padding: 0 1.5rem;
  min-height: 80vh;
  .label {
    text-align: center;
    font-weight: 700;
    font-size: 0.8vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .icon-label {
    font-weight: 500;
    font-size: 1vw;
  }
  .requirement-wrapper {
    .requirement-item {
      height: 20vw;
      border: 2px dashed #979797;
      margin: 20px;
      border-radius: 8px;
      text-align: center;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .requirement-name::after {
    content: ', ';
  }
  .requirement-name:last-child::after {
    content: '';
  }
}

.reupload-button {
  position: absolute;
  right: 23px;
  top: 10px;
}

.reupload-button-contract {
  position: absolute;
  right: 45px;
  top: 45px;
}

.icon-label {
  font-weight: 500;
  font-size: 1vw;
}
