.submenu-list {
	.submenu-item {
		padding: 24px 16px;
		border-bottom: 1px solid #EAEAEA;
		display: flex;
    align-items: center;
    text-decoration: none;

		.name,
    .icon-menu,
    .fas {
      color: $dms-gray-300;
    }

    .name {
      flex-grow: 1;
      line-height: 24px;
      margin-left: 8px;
    }

		&.active {
			background: $dms-green;
			.name {
				color: $white;
			}
		}
	}
}