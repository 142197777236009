@import '../../../../assets/styles/styles.scss';

.loadWalletDealerTransaction{
	&__container-table{
		@include containerTableBoxShadow;  
	}
}



