// Mixins

// Reset List
//
// Resets default list styling.
//
// @usage - `@include reset-list`
//
// Styleguide 10.1

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

// Hovers
//
// Create pseduo-classes for `:hover`, `:active`, and `:focus`.
//
// @usage - `@include hover`
//
// Styleguide 10.3

@mixin hover {
  &:hover,
  &:active,
  &:focus { @content; }
}

// Feature Detection
//
// $feature - Feature-specific class, i.e. `cssgradients`.
// @usage - `@include supported($feature)`
// @usage - `@include not-supported($feature)`

@mixin supported($feature) {
  .#{$feature} & {
    @content;
  }
}

@mixin not-supported($feature) {
  .no-js &,
  .no-#{$feature} & {
    @content;
  }
}

// Media Queries
//
// Allows you to use inline media queries. Includes options for `$fix-mqs`.
//
// $breakpoint - Desired breakpoint. Can be variable or unit.
// $query - Defaults to `min-width`.
// $type - Defaults to `screen`.

@mixin mq($breakpoint, $query: 'min-width', $type: 'screen') {
  @if $fix-mqs {
    @if $fix-mqs >= $breakpoint { @content; }
  } @else {
    @media #{$type} and (#{$query}: #{$breakpoint}) { @content; }
  }
}

// Clearfix
//
// Nicolas Gallagher's micro clearfix hack
//
// @source - http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after { clear: both; }
}

// Hide Text
//
// @usage - `@include hide-text`

@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

// Hidden
//
// Hide from both screenreaders and browsers
//
// @usage - `@include hidden`

@mixin hidden {
  display: none !important;
  visibility: hidden;
}

// Visually Hidden
//
// Hide only visually, but have it available for screenreaders
//
// @usage - `@include visuallyhidden`

@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p

  &.focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

// Invisible
//
// Hide visually and from screenreaders, but maintain layout
//
// @usage - `@include invisible`

@mixin invisible { visibility: hidden; }


// Custom Style for radio button
@mixin radioButtonStyle($position, $top, $right, $bottom, $left){
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    height: 1.1rem;
    width: 1.1rem;
    border: 2px solid #55c57a;
    border-radius: 50%;
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
    &:after{
        content: "";
        display: block;
        height: .58rem;
        width: .58rem;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #55c57a;
        opacity: 0;
        -webkit-transition: opacity .2s;
        -o-transition: opacity .2s;
        transition: opacity .2s; 
    }
}

// Table box shadow
@mixin containerTableBoxShadow{
    background: linear-gradient(to right, #fff, #fff), 
                linear-gradient(to right, #fff, #fff), 
                linear-gradient(to right, rgba(0, 0, 20, .5), rgba(255, 255, 255, 0)), 
                linear-gradient(to left, rgba(0, 0, 20, .5), rgba(255, 255, 255, 0));

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 25px 100%, 25px 100%, 20px 100%, 20px 100%;
    background-attachment: local, local, scroll, scroll;
    overflow-x: auto;
}
