@mixin sidebar-shrinked {
  width: 100px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  min-width: 100px;
  flex-basis: 100px;

  .header {
    flex-direction: column;
    padding: 1em;

    .btn {
      margin-bottom: 1em;
    }
  }

  .menu-list {
    overflow: initial;

    .menu-item {
      position: relative;

      .menu-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1em;

        .name {
          font-size: 0.75em;
          margin-left: 0;
          margin-top: 0.5em;
          text-align: center;
        }

        .fas {
          display: none;
        }
      }

      .menu-child {
        position: absolute;
        top: 2px;
        left: calc(6.5rem + 1.5rem / 2);
        z-index: 1;
        background-color: $white;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 0.75em;
        text-align: left;
        margin-left: 0;

        .menu-list {
          padding: 0.5em 0;
        }

        &-link {
          min-width: 15em;
        }
      }
    }
  }
}

.main-sidebar {
  background: #f7f7f7;
  min-width: 250px;
  flex-basis: 250px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;

  & > header {
    text-align: center;
  }

  &.shrinked {
    @include sidebar-shrinked;
      .menu-child {
      background: $color-primary;
      &.active {
        .menu-list {
          display: block;
        }
      }

      .menu-list {
        display: none;
        padding: 0 !important;
      }

      &-link {
        display: block;
        padding: 16px 24px;
        text-decoration: none;
        border-left: 3px solid transparent;
        border-bottom: 1px solid rgba($black, 0.2);
        &:last-child {
          border-bottom: 0;
        }

        .name {
          color: $dms-green;
          line-height: 1.25;
          font-weight: 600;
          opacity: 0.7;
        }

        &:hover,
        &:focus {
          background: darken($color-primary, 1%);
          border-left: 3px solid transparent;
          .name {
            color: rgba($white, 0.8);
          }
        }

        &.active {
          border-left: 3px solid $color-primary;
          background: darken($color-primary, 5%);
          .name {
            color: $white;
            opacity: 1;
          }
        }
      }
    }
  }

  &.ipad-menu {
    .menu-child {
      background: $color-primary;
      &.active {
        .menu-list {
          display: block;
        }
      }

      .menu-list {
        display: none;
        padding: 0 !important;
      }

      &-link {
        display: block;
        padding: 16px 24px;
        text-decoration: none;
        border-left: 3px solid transparent;
        border-bottom: 1px solid rgba($black, 0.2);
        &:last-child {
          border-bottom: 0;
        }

        .name {
          color: $dms-green;
          line-height: 1.25;
          font-weight: 600;
          opacity: 0.7;
        }

        &:hover,
        &:focus {
          background: darken($color-primary, 1%);
          border-left: 3px solid transparent;
          .name {
            color: rgba($white, 0.8);
          }
        }

        &.active {
          border-left: 3px solid $color-primary;
          background: darken($color-primary, 5%);
          .name {
            color: $white;
            opacity: 1;
          }
        }
      }
    }
  }

  .menu-list {
    list-style: none;
    padding: 0;
    overflow-y: auto;
    margin: 0;
  }
}

@include media-breakpoint-down(md) {
  .main-sidebar {
    @include sidebar-shrinked;
  }
}


.dropdown-main-menu {
  border-radius: 0;
  margin-top: -4px;
  width: 252px;
  margin-left: -1px;
  .menu-item .menu-child-link {
    padding: 16px;
  }
}
