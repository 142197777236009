.forgot-password-page {
  min-height: 100vh;
  .main-content {
    min-height: 100vh;
    width: 100%;
    div {
      z-index: 999;
    }
  }
  .main-content::before {
    content: '';
    height: 100%;
    background-image: url('../../images/page-background.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 25%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    transform: scale(-1);
  }
  .main-content::after {
    content: '';
    height: 100%;
    background-image: url('../../images/page-background.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 25%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .back-btn {
    font-weight: normal;
    color: $dms-gray-700;
    text-transform: initial;
  }

  .enter-otp-label {
    color: inherit;
    font-size: 1rem;
  }
}
