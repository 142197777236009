.first-login-page {
  min-height: 100vh;
  .main-content {
    min-height: 100vh;
    width: 100%;
  }
  .main-content::before {
    content: "";
    height: 100%;
    background-image: url("../../images/page-background.svg");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 25%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    transform: scale(-1);
  }
  .main-content::after {
    content: "";
    height: 100%;
    background-image: url("../../images/page-background.svg");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 25%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .front {
    z-index: 1;
  }

  .consent-tnc-content {
    width: 100%;
    max-width: 500px;
    overflow-y: auto;
    position: relative;
    border: 1px solid $gray-200;

    .consent-tnc-head {
      font-weight: 900;
      margin: 0px 20px 30px 0px !important;
    }

    h4 {
      margin: 0;
      font-size: 1.25rem;
    }
    .consent-tnc-text {
      width: 100%;
      max-width: 500px;
      overflow-y: auto;
      position: relative;
    }
    .consent-tnc-text::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    .consent-tnc-text::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    .consent-tnc-text::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #fff;
      background-image: -webkit-gradient(
        linear,
        40% 0%,
        75% 84%,
        from(#4cb673),
        to(#4cb673),
        color-stop(0.6, #4cb673)
      );
    }
  }

  .allow-list {
    * ol,
    * ul {
      margin-bottom: 1rem !important;
      list-style-type: revert !important;
      padding-left: 1.25rem !important;
    }
  }

  .ck-content {
    .text-tiny {
      font-size: 0.7em;
    }

    .text-small {
      font-size: 0.85em;
    }

    .text-big {
      font-size: 1.4em;
    }

    .text-huge {
      font-size: 1.8em;
    }

    blockquote {
      overflow: hidden;
      padding-right: 1.5em;
      padding-left: 1.5em;
      margin-left: 0;
      margin-right: 0;
      font-style: italic;
      border-left: 5px solid #ccc;
    }
  }

  .mdl-stepper-step {
    width: 20%;
  }

  .mdl-stepper-step.active-step .mdl-stepper-title {
    color: $dms-green;
    margin-top: 0;
    position: absolute;
    bottom: 45px;
    left: 0;
    right: 0;
    line-height: 1.2;
  }

  .update-profile-form {
    .form-control_bottom[readonly] {
      background-color: transparent;
    }
  }

  .cancel-btn,
  .prev-btn {
    text-transform: capitalize;
    text-decoration: underline;
    font-weight: normal;
    color: $dms-gray-700;
  }
}

.scroll-first {
  overflow-y: auto;
  height: 100px;
}

.scroll-first::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scroll-first::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.scroll-first::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4cb673),
    to(#4cb673),
    color-stop(0.6, #4cb673)
  );
}
