.bg-gray-100 {
  background-color: $gray-100 !important;
}
.bg-gray-200 {
  background-color: $gray-200 !important;
}
.bg-gray-300 {
  background-color: $gray-300 !important;
}
.bg-gray-400 {
  background-color: $gray-400 !important;
}
.bg-gray-500 {
  background-color: $gray-500 !important;
}
.bg-gray-600 {
  background-color: $gray-600 !important;
}
.bg-gray-700 {
  background-color: $gray-700 !important;
}
.bg-gray-800 {
  background-color: $gray-800 !important;
}
.bg-gray-900 {
  background-color: $gray-900 !important;
}

.bg-card-header {
  background-color: #818181;
  color: white;
}

/* Light Background */
.bg-light-0 {
  background: rgb(255, 255, 255) !important;
}
.bg-light-100 {
  background: rgb(240, 240, 240) !important;
}
.bg-light-200 {
  background: rgb(230, 230, 230) !important;
}
.bg-light-300 {
  background: rgb(220, 220, 220) !important;
}
.bg-light-400 {
  background: rgb(210, 210, 210) !important;
}
.bg-light-500 {
  background: rgb(200, 200, 200) !important;
}

/* Grey Background */
.bg-grey-100 {
  background: rgb(190, 190, 190) !important;
}
.bg-grey-200 {
  background: rgb(180, 180, 180) !important;
}
.bg-grey-300 {
  background: rgb(170, 170, 170) !important;
}
.bg-grey-400 {
  background: rgb(160, 160, 160) !important;
}
.bg-grey-500 {
  background: rgb(150, 150, 150) !important;
}
.bg-grey-600 {
  background: rgb(140, 140, 140) !important;
}
.bg-grey-700 {
  background: rgb(130, 130, 130) !important;
}
.bg-grey-800 {
  background: rgb(120, 120, 120) !important;
}
.bg-grey-900 {
  background: rgb(110, 110, 110) !important;
}
.bg-grey-1000 {
  background: rgb(100, 100, 100) !important;
}
.bg-grey-1100 {
  background: #cacaca5c !important;
}
/* Dark Background */
.bg-dark-100 {
  background: rgb(90, 90, 90) !important;
}
.bg-dark-200 {
  background: rgb(80, 80, 80) !important;
}
.bg-dark-300 {
  background: rgb(70, 70, 70) !important;
}
.bg-dark-400 {
  background: rgb(60, 60, 60) !important;
}
.bg-dark-500 {
  background: rgb(50, 50, 50) !important;
}
.bg-dark-600 {
  background: rgb(40, 40, 40) !important;
}
.bg-dark-700 {
  background: rgb(30, 30, 30) !important;
}
.bg-dark-800 {
  background: rgb(20, 20, 20) !important;
}
.bg-dark-900 {
  background: rgb(10, 10, 10) !important;
}
.bg-dark-1000 {
  background: rgb(0, 0, 0) !important;
}

.bg-light-grey {
  background: #ecedf2;
}
