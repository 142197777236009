@keyframes bounce {
 0%, 20%, 60%, 100% {
   transform: translateY(0);
 }

 40% {
   transform: translateY(-20px);
 }

 80% {
   transform: translateY(-10px);
 }
}

@keyframes pulsate {
  0% {
		transform: translateY(0px);
		box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  50% {
		transform: translateY(-5px);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.10);
  }
  100% {
		transform: translateY(0px);
		box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}
