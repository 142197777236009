.form-wrapper {
  .full-group {
    margin-bottom: 16px;
    position: relative;

    & .form-control {
      &:focus {
        box-shadow: none;
      }
    }
    & > .full-input {
      outline: none;
      display: block;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      width: 100%;
      padding: 16px 16px 16px 48px;
      color: $black;
      font-weight: 600;
      width: 100%;
      border: 1px solid $black;
      border-radius: 8px;

      &.with-pass-icon {
        background: url("../../images/icon-lock.svg") no-repeat center left,
          $white;
      }

      &.with-user-icon {
        background: url("../../images/icon-user.svg") no-repeat center left,
          $white;
      }

      &::-webkit-input-placeholder {
        color: #d0ccd0;
      }
      &:-ms-input-placeholder {
        color: #d0ccd0;
      }
      &::placeholder {
        color: #d0ccd0;
      }
    }
    & > .input-group-append {
      position: absolute;
      right: 0;
      height: 54px;
      bottom: 0;

      & > .password-eye {
        background: none;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
    & > .full-label {
      display: block;
      font-size: 14px;
      margin-bottom: 16px;
      line-height: 24px;
      font-weight: 600;
      color: $black;
    }
    .form-row {
      .form-input-group:nth-child(1) {
        @include media-breakpoint-down(sm) {
          border-bottom: 1px solid #d1d1d1;
          border-right: 0 solid transparent !important;
        }
      }
      .form-input-group {
        & > .full-input {
          outline: none;
          border: none;
          display: block;
          line-height: 0.9em;
          font-size: 10pt;
          width: 100%;
          padding: 10px;
          color: $color-secondary;
          font-weight: 600;
          width: 100%;
          line-height: 22px;

          &:focus {
            border-left: 1px solid red !important;
          }

          &.date-picker {
            width: calc(100% - 46px);
            display: inline;
          }
          &::-webkit-input-placeholder {
            color: #d0ccd0;
          }
          &:-ms-input-placeholder {
            color: #d0ccd0;
          }
          &::placeholder {
            color: #d0ccd0;
          }
        }
        .full-label {
          display: block;
          font-size: 12px;
          color: #484848;
          padding: 6px 10px 0;
        }
        .full-select {
          box-shadow: none;
          outline: none;
          border: none;
          display: block;
          line-height: 0.9em;
          font-size: 10pt;
          width: 100%;
          color: $color-secondary;
          font-weight: 600;
          width: 100%;
          background: transparent;

          &::-webkit-input-placeholder {
            color: #d0ccd0;
          }
          &:-ms-input-placeholder {
            color: #d0ccd0;
          }
          &::placeholder {
            color: #d0ccd0;
          }
        }
      }
    }
  }
}

.input-group {
  position: relative;
  margin-right: 0;
  .form-control-date {
    border-radius: 5px !important;
    padding: 1.2rem 0.75rem !important;
    border: 1px solid #cccccc !important;
  }
  .input-group-btn {
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 5;
    .btn {
      padding: 6px;
      background: transparent;
      line-height: 16px;
    }
  }
  .form-control-group {
    border-radius: 5px !important;
    padding-left: 40px !important;
  }
  .input-group-text {
    position: absolute;
    left: -1px;
    height: 39px;
    border: none;
    color: #818181;
    background: none;
    z-index: 5;
  }
}

.form-datepicker {
  width: 110px;
  border: none;
  box-shadow: none;
}
.form-append-blank {
  background: none;
  border: none;
}

.css-1uccc91-singleValue {
  color: #4c6085;
  font-weight: 600;
  font-size: 10pt;
}

.sector-section {
  border: 1px solid #d9d9d9;
  border-radius: 5px;

  & .sector-table-list {
    & .table-label {
      font-size: 13px;
      color: $color-text;
    }
    & .table-content-list {
      font-size: 13px;
      color: $color-text;
    }
  }
}

.filter-status {
  background: none;
  border: none;
  color: $color-secondary;

  &:hover {
    transition: all 100ms ease-in-out;
    transform: scale(1.03);
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.prepend-form-right {
  position: relative;

  .btn {
    position: absolute;
    bottom: 11px;
    right: 11px;
    z-index: 1;
  }
}

.prepend-input-group {
  position: relative;

  .input-group-prepend {
    position: absolute;
    left: 0;
    height: 100%;

    .input-group-text {
      border: 0;
      background-color: transparent;
      color: $dms-gray-300;
    }
  }

  .input-textbox {
    padding: 1.5em 1.5em 1.5em 2.75em;
  }
}

.custom-range-group {
  position: relative;
  .range-value {
    position: absolute;
    top: 8px;
    height: 8px;
    background: $dms-green;
    border-radius: 50px;
  }
}

.form-control {
  &.form-custom {
    height: 40px;
    padding: 8px 8px;
    box-shadow: none;
    &.custom-select {
      height: 40px;
    }
    &.custom-date {
      background: transparent;
    }
  }
  &_bottom {
    border-bottom: 1px solid $dms-gray-300;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    background: transparent;
    &[readonly] {
      background: $white;
    }
  }
}

.filter-by {
  width: 130px;
}

.input-group-search {
  .form-control {
    border: 1px solid $color-primary;
    box-shadow: none;

    &:not(:last-child) {
      border-right-width: 0;
    }
  }

  .btn {
    background: white;
    border: 1px solid $color-primary;
    border-left-width: 0;
    color: $gray-500;
    line-height: 1;
  }
}

.lighter_placeholder_color {
  &::placeholder {
    color: #b3b3b3;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #b3b3b3;
  }

  &::-ms-input-placeholder {
    color: #b3b3b3;
  }
}

.upload-field {
  border: 2px dashed $gray-200;
  border-radius: 4px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6em;
  cursor: pointer;
  outline: none;

  &:focus {
    border-color: lighten($color-primary, 30%);
  }
}

.retailer-sims-container {
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.card-content {
  border-radius: 6px;
  div.row {
    border: none !important;
  }
}

.filter-form {
  border-radius: 5px !important;
  padding: 1.2rem 0.75rem !important;
  border: 1px solid #cccccc !important;
}

.min-filter {
  min-width: 175px;
}
