.network-logo {
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid transparent;
	height: 75px;
	width: 75px;
	margin: 0 auto 16px;
	// display: block;
	&.selected {
		border-color: $dms-green;
	}
	&.not-selected {
		opacity: 0.8;
		& > .image {
			filter: grayscale(100%);

		}
	}
	.image {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center center;
	}
}

.network-form-list {
  @include media-breakpoint-down(lg) {
    column-count: 2;
    column-gap: 16px;
  }
  @include media-breakpoint-up(lg) {
    column-count: 3;
    column-gap: 16px;
  }
}