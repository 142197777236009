@viewport {
  width: device-width;
}

html {
  background-color: $color-background;
  box-sizing: border-box;
  height: 100%;
  font-size: 15px;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  height: 0; /* remove scrollbar space */
  width: 0; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
  position: relative;
}

* {
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  color: $color-text;
  font-family: $font-family-default;
  min-height: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  background-color: white;
}

a {
  background: transparent;
  color: gray;
  text-decoration: none;
  transition: color 0.2s;
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    box-shadow: none;
  }
}

img {
  height: auto;
  max-width: 100%;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-display;
  font-weight: normal;
}
