.contact-details {
  .email-address {
    font-weight: 600;
    color: $color-secondary;
  }
  .mobile-number {
    font-weight: 600;
    color: $color-secondary;
  }
}
