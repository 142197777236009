.react-pdf__Page {
  max-width: 100%;
  min-width: 100%;
  border: 1px solid #e8e8e88a;
}

.react-pdf__Page::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.react-pdf__Page::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.react-pdf__Page::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4cb673),
    to(#4cb673),
    color-stop(0.6, #4cb673)
  );
}

.react-pdf__Page__annotations {
  display: none;
}

.control-panel {
  background: #3a3a3a;
  color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
}

.min-pdf {
  padding-top: 5rem;
  background: #676767;
  min-height: 100vh;
}

#pdf-section {
  overflow: auto;
}
