@import '../../../../assets/styles/styles.scss';

.custom-border-bottom {
  height: 1.5px;
  background: rgb(220, 220, 220);
  margin: 0 auto;
  width: 93.5%;
}

.transferTo {
  &__check-group-container {
    padding-left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__select-options {
    border-radius: 4px;
    svg {
      color: #2dbe60;
    }
  }

  &__check-input {
    display: none;
    &:checked
      ~ .transferTo__radio-label-container
      .transferTo__radio-button::after {
      opacity: 1;
    }
  }

  &__radio-label {
    margin-left: 1.5rem;
    color: rgb(100, 100, 100);
    cursor: pointer;
  }

  &__radio-label-container {
    position: relative;
  }
  .form-control_bottom {
    border-color: #2dbe60;
  }

  &__radio-button {
    @include radioButtonStyle(absolute, unset, unset, 4px, 0px);
  }
  .css-1okebmr-indicatorSeparator {
    opacity: 0;
  }
  .css-1uccc91-singleValue {
    color: rgb(140, 140, 140);
    font-weight: 500;
  }
}
