.ReactModal__Body--open {
  overflow: hidden;
}
.ReactModal__Overlay--after-open {
  z-index: 7777;
}
.modal-header {
  background: #089444;
  color: $white;
  border-top-left-radius: 0.18rem;
  border-top-right-radius: 0.18rem;
  padding: 0.75rem 1.25rem;

  .close {
    color: $white;
    outline: none;
    opacity: 1;
  }
}

.modal-margin {
  margin: 15.75rem auto;
}

.modal-xl {
  max-width: 80%;
}

.modal-sm {
  max-width: 340px;
}

.modal-medium {
  max-width: 700px;
}

.modal-fullscreen {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  div.modal-content {
    background: transparent;
    height: 100%;
    border-radius: 0;
  }
}

.modal-center {
  width: 100%;
  height: 85%;
  justify-content: center;
  align-items: center;
  display: flex;
}
