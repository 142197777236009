.issue-file-upload {
  .file-upload-container {
    @extend .form-control;

    height: 100%;
    display: flex;
    overflow-x: scroll;

    .file-container {
      display: block;
      width: 100px;
      height: 75px;

      &-img {
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
