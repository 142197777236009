.tooltip {
  max-width: 25rem;
}

.tooltip-small {
  max-width: 15rem;
}

.ellipsis-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-tooltip {
  font-size: 0.75rem;
  margin-right: -10px;
  margin-top: 10px;
  position: relative;
  z-index: 1;
}

.supporting-doc-icon {
  font-size: 0.75rem;
  margin-left: 5px;
}

.max-header {
  max-width: 15rem;
}
