.loadRetailer{

	// Success message
	&__success-alert-container{
	    font-size: .875rem;
        color: white;
        top: 0px;
        left: 0px;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding-left: 2.125rem;
        padding-right: 2.125rem;
	}

	&__success-alert{
		 border-top-style: none;
	     border-right-style: none;
	     border-bottom-style: solid;
	     border-left-style: solid;
	     border-width: 0 0 0.1875rem 0.1875rem;
	     border-color: white;
	     width: 1.5rem;
	     height: 0.625rem;
	     display: inline-block;
	     transform: rotate(-45deg);
	     top: '-3';

	     .span{
	     	margin-left: 2.125rem;
	     }
	}

	&__success-close{
		color: #fff;
		opacity: 1
	}

	// Box container after 'Select dealer MIN wallet.... title'
	&__box-container{
		  box-shadow: 0px 3px 6px #00000029;
          border-radius: 10px;
          position: relative;
	}

	//Load wallet balance container
	&__loadBalance{
		&-container{
		    background: #2DBE60;
	        border-radius: 10px 0 0 10px;
	        padding: 2.25rem;
	        color: white;
		}

 		&-spinner{
			top: 45%;
			left: 45%;
		}

		&-title{
		  font-size: 1.25rem;
          font-weight: bold;
          margin-bottom: 1.5rem;
		}

		&-branch-group,
		&-dealerMin-group,
		&-threshold-group{
			min-width: 16.5rem;
			width: 20vw;
		}

		&-branch-label{
			font-size: .875rem;
            font-weight: bold;
            color: white;
            margin-bottom: .5rem;
		}

		&-branch-input{
			color: #fff!important;
            font-size: 1.125rem!important;
            font-height: normal!important;
            background: transparent!important;
		}

		&-dealerMin-select-container{
			color: #585858;
			z-index: 99;
		}

		&-dealerMin-label{
			font-size: .875rem;
	        font-weight: bold;
	        color: white;
	        margin-bottom: .5rem;
		}

		&-threshold-input{
			font-size: .875rem;
            font-weight: bold;
            color: white;
            margin-bottom: .5rem;
		}
	}
	

	// Transfer Load to container
	&__transfer{
		&-container{
			padding: 2.25rem; 
			z-index: 2;
		}

		&-img{
			position: absolute; 
			bottom: 1.5rem; 
			right: 1.5rem;
		}

		&-feedback{
		  height: 1.5rem;
	      color: IndianRed;
	      fontSize: .875rem;
		}

		&-title{
			font-size: 1.25rem;
	        font-weight: bold;
	        color: #585858;
	        margin-bottom: 1.5rem;
		}

		&-proceed{
			&-button-container{
				background: #fff;
			    position: absolute;
			    bottom: 3rem;
			    right: 4rem;
				    @media only screen and(max-width: 75em){
			    	    bottom: 2rem;
			    		right: 2rem;	
					}

				    @media only screen and(max-width: 70em){
				    	position: unset!important;
				    	width: 100%;
				    }
			}

			&-button{
				width: 10rem!important;
				   @media only screen and(max-width: 70em){
			    		width: 100%!important;
				}
			}
		}
	}
}




