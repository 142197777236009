.icon-menu {
  width: 24px;
  height: 24px;
  fill: #cfd2df;
}

.icon-cloud {
  width: 100px;
  height: 100px;
  fill: $gray-400;
}

.icon {
  width: 24px;
  height: 24px;
  fill: $gray-400;
}

.icon-helpdesk {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}


.icon-action {
  width: 24px;
  height: 24px;
  fill: $dms-green;
}