.modal {
  overflow-y: auto;
  z-index: 7777;
  display: block;
  pointer-events: 'none';
  .modal-open {
    overflow: hidden;
  }

  .modal-content {
    border-radius: 8px;
    margin: 20px 0 30px;
    border: 0;
  }
  .modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 14px;
    padding-bottom: 14px;
    .modal-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
    .close {
      outline: none;
      opacity: 1;
    }
  }
  .modal-body {
    padding: 24px 32px;
  }
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  & > .show {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0.8;
  }
}

.modal-xl {
  max-width: 900px;
}

.modal-xl {
  max-width: 1200px;
}
