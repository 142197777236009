.nav-tabs {
  border-bottom: 0;

  .nav-item {
    .nav-link {
      font-size: 0.85em;
      border-color: $dms-gray-100;
      color: $dms-green;
      font-weight: 500;
      background-color: $white;

      &:hover {
        background-color: rgba(#60bb45, 0.07);
      }

      &.active {
        background-color: $dms-green;
        color: $white;
      }
    }
    .nav-loading {
      padding: 0.5rem 5rem !important;
      div {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.nav-tabs-baselined {
  border-bottom: solid 4px #049051;
  overflow-y: hidden;

  .nav-item {
    .nav-link {
      font-size: 1.125em;
      font-weight: bold;
      background-color: #2dbe60;
      color: $white;
      padding: 0.875em 2.125em 0.875em 2.125em;
      border-radius: 0.5em 0.5em 0em 0em;

      &.active {
        background-color: #049051;
      }
    }
  }
}

.tab-baselined-content {
  border: solid 1px #d8d8d8;
  border-radius: 0px 0px 4px 4px;
}

.buttonWithCheckbox-input {
  display: none;
}

.buttonWithCheckbox-button {
  cursor: pointer;

  border: solid 1px #d5d5d5;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  color: #00a259;
  padding: 0.375em 0.8125em 0.3125em 0.5625em;
  margin-right: 1em;
}

.buttonWithCheckbox-input:checked + .buttonWithCheckbox-button {
  color: #ffffff;
  background-color: #00a259;
}

.buttonWithCheckbox-checkbox {
  position: relative;
}

.buttonWithCheckbox-box {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0px;
  background: #ffffff;
  border: solid 1px #d5d5d5;
  border-radius: 4px;
}

.buttonWithCheckbox-check {
  content: '';
  width: 10px;
  height: 6px;
  position: absolute;
  top: 7px;
  left: 3px;
  border: 2px solid #00a259;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.buttonWithCheckbox-text {
  font-size: 16px;
  margin: 0 0 0 25px;
}
