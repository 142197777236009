.partners {
  &__select-options {
    border-radius: 5px;
    svg {
      color: #2dbe60;
    }
    .css-1okebmr-indicatorSeparator {
      opacity: 0;
    }
  }

  &__bg-light-gray {
    background: #ecedf2;
  }
  &__dealer {
    @media only screen and (max-width: 49.5em) {
      &-add-dealer {
        font-size: 0.8rem !important;
      }
    }
  }

  @media only screen and (max-width: 35.93em) {
    &__dealer {
      margin-bottom: 1.5rem !important;
    }

    &__dealer-card-container {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }

    &__dealer-card-item {
      margin-bottom: 5rem !important;
    }

    &__dealer-action-title {
      display: none !important;
    }
  }
}

.partners__filter {
  @media only screen and (max-width: 49.5em) {
    &-advance-btn,
    &-input,
    &-search-btn {
      font-size: 0.8rem !important;
    }
  }

  &-input {
    @media only screen and (max-width: 49.5em) {
      height: 2.2rem !important;
    }
  }

  &-input-container {
    @media only screen and (max-width: 45.375em) {
      width: 90%;
    }
  }

  &-label {
    @media only screen and (max-width: 49.5em) {
      font-size: 0.9rem !important;
    }
  }

  &-advance-button-container--1 {
    @media only screen and (max-width: 45.375em) {
      display: none;
    }
  }

  &-advance-button-container--2 {
    display: none;
    @media only screen and (max-width: 45.375em) {
      display: unset;
    }
  }
}

.supporting__docs {
  &-image-container {
    overflow: hidden;
    background: rgb(50, 50, 50);

    &:hover {
      cursor: zoom-in;

      .supporting__docs-image {
        transform: unset !important;
        opacity: 0.3;
        filter: blur(2px);
        -webkit-filter: blur(2px);
      }

      .supportingDocuments__icon-container {
        opacity: 1 !important;
        visibility: unset;
      }
    }
  }

  &-image {
    transition: all 0.3s;
    transform: scale(1.7);
  }
}

.viewImage {
  &__overlay {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 9000;
  }
  &__image-container {
    z-index: 9999;
  }
}

.supportingDocuments {
  &__icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    transition: all 0.5s;
    opacity: 0 !important;
    visibility: hidden;
  }
  &__icon {
    font-size: 5rem;
  }
}

.partners__retailer {
  &-remarks-container {
    top: 0.5rem;
    left: -1.65rem !important;
    z-index: 10;
    width: 6rem;
  }

  &-remarks-icon-container {
    &:hover + .partners__retailer-remarks-description {
      display: block !important;
    }
  }
  &-remarks-description {
    display: none;
    opacity: 0.7;
    border-radius: 0.3rem;
    font-size: 0.6rem;
  }

  &__retailer {
    @media only screen and (max-width: 49.5em) {
      &-add-dealer {
        font-size: 0.8rem !important;
      }
    }
  }

  @media only screen and (max-width: 35.93em) {
    &__retailer {
      margin-bottom: 1.5rem !important;
    }

    &__retailer-card-container {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }

    &__retailer-card-item {
      margin-bottom: 5rem !important;
    }

    &__retailer-action-title {
      display: none !important;
    }
  }
}
