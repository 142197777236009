.layout {
  display: flex;
  height: 100vh;

  .main-content {
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &-inner {
      padding: 24px;
      flex-grow: 1;
    }
  }
}

.main-header {
  background-color: #fcfcfc;
}
