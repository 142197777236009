.loader-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1500;
}
.loader-inner {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  position: relative;
  img {
    width: 100%;
  }

}

.loader-circle:before,
.loader-circle:after{
    top:0;
    bottom:0;
    left:0;
    right:0;
    content:"";
    position:absolute;
    border-radius:50%;
  }

  .loader-circle{
    position: absolute;
    top: -10px;
    left: -10px;
    width:120px;
    height:120px;
    border-radius: 100%;
    margin:auto;
    animation:spin 4s linear infinite;
  }

  @keyframes spin{
    100%{
      transform:rotate(360deg);
    }
  }

  .loader-circle:before{
    border:5px solid $color-background;
    border-bottom:5px solid $color-secondary;
    border-left:5px solid $color-secondary;
    animation:spin1 1s linear infinite;
  }

  .loader-circle:after{
    border:5px solid $color-background;
    border-top:5px solid transparent;
    border-right:5px solid transparent;
    animation:spin2 1s linear infinite;
  }

  @keyframes spin1{
    20%{transform:rotate(150deg)}
    40%{transform:rotate(300deg)}
    80%{transform:rotate(300deg)}
    100%{transform:rotate(360deg)}
  }

  @keyframes spin2{
    0%{transform:rotate(-30deg)}
    20%{transform:rotate(-30deg);
        border-color:transparent transparent $color-background $color-background}
    21%{border-color:$color-secondary $color-secondary transparent transparent}
    40%{transform:rotate(-30deg)}
    60%{transform:rotate(120deg);
        border-color:$color-secondary $color-secondary transparent transparent}
    61%{border-color:transparent transparent $color-background $color-background}
    80%{transform:rotate(270deg)}
    100%{transform:rotate(330deg);}
  }