.sales-order-wrapper {
  display: grid;
	grid-gap: 16px;
  grid-template-columns: 250px calc(100% - 266px);
	grid-template-rows: 260px 300px calc(calc(100vh - 100px) - 560px);
	height: 100%;
	width: 100%;
	@include media-breakpoint-only(lg) {
		grid-template-columns: 250px calc(100% - 266px);
		grid-template-rows: 270px 300px calc(calc(100vh - 100px) - 580px);
	}
	& > .mobile-loaded {
		grid-area: 1 / 1 / 2 / 2;
	}
	& > .mobile-wallet {
		grid-area: 2 / 1 / 3 / 2;
	}
	& > .offer-list {
		grid-area: 1 / 2 / 4 / 4;
		max-height: 100%;
	 }
}

.cash-credit-wrapper {
	border: 1px solid lightgray;
	border-radius: 6px;
	width: 100px;
	height: 36px;
	&:focus, &:hover{
    border-color: #88e3a8;
  }
}

.cash-credit-wrapper.active {
	border: 1px solid #88e3a8;
	border-radius: 6px;
	width: 100px;
	height: 36px;
}

.product-image-wrapper {
	flex-wrap: wrap;
  white-space: nowrap;
  overflow-x: auto;
  width: 250px;
}

.border-custom-top {
	border-top: 1px solid green;
}

.border-custom-bottom {
	border-bottom: 1px solid green;
}