.border-secondary {
  border-color: $color-secondary !important;
}

.border-dash {
  border: 2px dashed #eee;
}

.border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.rounded-top-both{
	border-top-left-radius: 7px!important;
	border-top-right-radius: 7px!important;
}

.rounded-top-both-1{
	border-top-left-radius: 5px!important;
	border-top-right-radius: 5px!important;
}

.rounded-top-right{
	border-top-right-radius: 7px!important;
}

.rounded-top-left{
	border-top-left-radius: 7px!important;
}

.rounded-bottom-both{
	border-bottom-left-radius: 7px!important;
	border-bottom-right-radius: 7px!important;
}

.rounded-bottom-both-1{
	border-bottom-left-radius: 5px!important;
	border-bottom-right-radius: 5px!important;
}

.rounded-bottom-right{
	border-bottom-right-radius: 7px!important;
}

.rounded-bottom-left{
	border-bottom-left-radius: 7px!important;
}


.rounded-all-corner{
	border-radius: 7px!important;
}

.border-gray-100 { border-color: $gray-100 !important; }
.border-gray-200 { border-color: $gray-200 !important; }
.border-gray-300 { border-color: $gray-300 !important; }
.border-gray-400 { border-color: $gray-400 !important; }
.border-gray-500 { border-color: $gray-500 !important; }
.border-gray-600 { border-color: $gray-600 !important; }
.border-gray-700 { border-color: $gray-700 !important; }
.border-gray-800 { border-color: $gray-800 !important; }
.border-gray-900 { border-color: $gray-900 !important; }

