.break-word {
  word-break: break-word;
}

.date-reg {
  font-size: 14px;
}

.expiry-date {
  font-size: 14px;
}

.form-label {
  font-size: 14px;
  color: $dms-gray-400;
  font-weight: 500;
}

.text-title {
  font-size: 1.5em;
}

.text-subtitle {
  font-size: 1.125em;
  color: $dms-gray-400;
  line-height: 1.5;
}

.text-normal {
  text-transform: initial;
}

.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-xxl {
  font-size: 1.5rem;
}
.text-2x {
  font-size: 2rem;
}
.text-3x {
  font-size: 3rem;
}

.lh-1 {
  line-height: 1;
}

/* Light  */
.text-light-0 {
  color: rgb(255, 255, 255) !important;
}
.text-light-100 {
  color: rgb(240, 240, 240) !important;
}
.text-light-200 {
  color: rgb(230, 230, 230) !important;
}
.text-light-300 {
  color: rgb(220, 220, 220) !important;
}
.text-light-400 {
  color: rgb(210, 210, 210) !important;
}
.text-light-500 {
  color: rgb(200, 200, 200) !important;
}

/* Grey  */
.text-grey-100 {
  color: rgb(190, 190, 190) !important;
}
.text-grey-200 {
  color: rgb(180, 180, 180) !important;
}
.text-grey-300 {
  color: rgb(170, 170, 170) !important;
}
.text-grey-400 {
  color: rgb(160, 160, 160) !important;
}
.text-grey-500 {
  color: rgb(150, 150, 150) !important;
}
.text-grey-600 {
  color: rgb(140, 140, 140) !important;
}
.text-grey-700 {
  color: rgb(130, 130, 130) !important;
}
.text-grey-800 {
  color: rgb(120, 120, 120) !important;
}
.text-grey-900 {
  color: rgb(110, 110, 110) !important;
}
.text-grey-1000 {
  color: rgb(100, 100, 100) !important;
}

/* Dark  */
.text-dark-100 {
  color: rgb(90, 90, 90) !important;
}
.text-dark-200 {
  color: rgb(80, 80, 80) !important;
}
.text-dark-300 {
  color: rgb(70, 70, 70) !important;
}
.text-dark-400 {
  color: rgb(60, 60, 60) !important;
}
.text-dark-500 {
  color: rgb(50, 50, 50) !important;
}
.text-dark-600 {
  color: rgb(40, 40, 40) !important;
}
.text-dark-700 {
  color: rgb(30, 30, 30) !important;
}
.text-dark-800 {
  color: rgb(20, 20, 20) !important;
}
.text-dark-900 {
  color: rgb(10, 10, 10) !important;
}
.text-dark-1000 {
  color: rgb(0, 0, 0) !important;
}

.text-gray-100 {
  color: $gray-100 !important;
}
.text-gray-200 {
  color: $gray-200 !important;
}
.text-gray-300 {
  color: $gray-300 !important;
}
.text-gray-400 {
  color: $gray-400 !important;
}
.text-gray-500 {
  color: $gray-500 !important;
}
.text-gray-600 {
  color: $gray-600 !important;
}
.text-gray-700 {
  color: $gray-700 !important;
}
.text-gray-800 {
  color: $gray-800 !important;
}
.text-gray-900 {
  color: $gray-900 !important;
}

.text-xs {
  span.badge {
    font-size: 0.75rem;
  }
}

.w-7 {
  width: 7rem;
}

a.text-green {
  color: $dms-green !important;
  &:hover {
    color: $dms-green-700 !important;
  }
}

.spinner-small {
  width: 1.5rem;
  height: 1.5rem;
}
