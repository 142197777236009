tr:hover.generic-active,
.generic-active {
  background: #eff0f3;
  &:focus,
  &:hover {
    background-color: #eff0f3;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: inherit;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

