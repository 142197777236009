.box-fit-wrapper {
  position: relative;
  min-height: 100px;
  will-change: transform;

  & > .box-fit-overflow {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0;
    left: 0;
    overflow: auto;
    will-change: transform;

    & .list-group-item {
      border-radius: 0;
      padding: 10px 24px;
      border-top: 1px solid $card-dark-bg;
      border-right: none;
      border-bottom: 1px solid $card-dark-bg;
      border-left: none;

      &.active {
        background-color: $card-dark-bg;
        color: $color-secondary;

        & .sidebar-name,
        .sidebar-position {
          color: $color-secondary;
        }
      }
    }
  }

  & .box-fit-sidebar {
    padding: 0;
  }

  & .filter-section {
    padding: 24px 24px 0 24px;
  }
}

.box-fit-label {
  color: #313131;
  font-size: 13px;
}

.box-fit-button {
  padding: 24px;
}

.sidebar-name {
  font-size: 15px;
}

.sidebar-position {
  font-size: 11px;
  color: #696969;
}

.box-fit-refresh {
  color: $color-secondary;
  font-size: 13px;
}
