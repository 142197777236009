// Overrides
//
// [These styles override] all other layers, and has the power
// to override anything at all that has gone before it. It is
// inelegant and heavy-handed, and contains utility and helper
// classes, and hacks.
//
// @source - http://www.creativebloq.com/web-design/manage-large-css-projects-itcss-101517528

.react-datepicker-popper {
  z-index: 99999 !important;
}

.react-datepicker__year,
.react-datepicker__monthPicker {
  width: 210px;
}

.react-datepicker-outer {
  z-index: 1021 !important;
}

.swagger-ui .model-box {
  max-width: 100%;
}

.swagger-ui .model .property {
  word-break: break-word;
}
