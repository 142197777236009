.progress-bar {
	height: 30px;
	width: 100%;
	border-radius: 4px;
	background: $dms-gray-100;
	overflow: hidden;
	position: relative;
		& > .percent-text {
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		z-index: 2;
		position: relative;
	}
	& > .progress-bar-percentage {
		height: 30px;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		padding: 5px 10px;
		z-index: 1;
		&.percent-red {
			background: $dms-red;
			z-index: 1;
			& + .percent-text {
				color: $black;
			}
		}
		&.percent-orange {
			background: $color-warning;
			z-index: 1;
			& + .percent-text {
				color: $white;
			}
		}
		&.percent-green {
			background: $dms-green;
			z-index: 1;
			& + .percent-text {
				color: $white;
			}
		}
	}
}