.user-dropdown {
  display: flex;
  flex-flow: row;
  align-items: center;
  .info {
    margin-left: 8px;
    .name {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}

.animated--grow-in {
  animation-name: growIn;
  animation-duration: 200ms;
  animation-timing-function: transform cubic-bezier(.18,1.25,.4,1), opacity cubic-bezier(0,1,.4,1);
}
