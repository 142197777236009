.menu-item {
  .menu-link {
    padding: 10px 16px;
    border: 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    font-weight: 600;

    .name,
    .icon-menu,
    .fas {
      color: $gray-600;
    }

    .name {
      flex-grow: 1;
      line-height: 24px;
      margin-left: 8px;
    }

    .fas {
      font-size: 0.75em;
      margin-left: 0.5em;
    }

    &:hover,
    &:focus {
      background: rgba($color-primary, 0.07);
    }

    &.active {
      border-left: 6px solid $color-primary;
      background: rgba($color-primary, 0.1);

      .name,
      .icon-menu,
      .fas {
        color: $dms-green-700;
      }
    }

    &.disable {
      background: lightgray;
      cursor: not-allowed;
    }
  }

  .menu-child {
    background: $color-primary;
    &.active {
      .menu-list {
        display: block;
      }
    }

    .menu-list {
      display: none;
    }

    &-link {
      display: block;
      padding: 10px 24px;
      text-decoration: none;
      border-left: 6px solid $color-primary;

      .name {
        color: $white;
        line-height: 1.25;
        font-weight: 600;
        opacity: 0.7;
      }

      &:hover,
      &:focus {
        background: darken($color-primary, 3%);
        border-left-color: darken($color-primary, 3%);
        .name {
          opacity: 1;
        }
      }

      &.active {
        border-left: 6px solid darken($color-primary, 5%);
        background: darken($color-primary, 5%);
        .name {
          color: $white;
          opacity: 1;
        }
      }
    }
  }
}
