.custom-legend {
	display: flex;
	align-items: center;
	.color-indicator {
		width: 18px;
		height: 8px;
		border-radius: 8px;
		display: inline-block;
	}

	.text {
		font-size: 12px;
		color: $dms-gray-700;
		margin-left: 8px;
	}
	&.green {
		.color-indicator {
			background: $dms-green;
		}
	}
	&.blue {
		.color-indicator {
			background: $dms-smartbro;
		}
	}
}