.scroll-lime {
  overflow: auto;
}

.scroll-lime::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scroll-lime::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  height: 10px;
  position: unset;
}

.scroll-lime::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4cb673),
    to(#4cb673),
    color-stop(0.6, #4cb673)
  );
}
