.nav-tabs {
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
}
.tab-link {
  height: 60px;
  background-color: rgba($white, 0.5);
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  color: rgba($black, 0.7);
  text-decoration: none;
  font-family: $font-family-sans;
  font-weight: 500;
  transition: all 0.1s ease-in-out 0s;
  .extra {
    color: #009838;
    font-weight: 400;
    display: inline-block;
    margin-left: 5px;
    font-size: 0.875rem;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: $dms-green;
  }
  &.active {
    font-weight: 500;
    background: $dms-green;
    color: $white;
    border: 1px solid $dms-green;
    border-bottom: none;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $white;
    }
  }
  &.small {
    height: 52px;
    font-size: 0.875rem;
    line-height: 52px;
    .extra {
      font-size: 0.75rem;
    }
  }
  &.smaller {
    height: 40px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    line-height: 40px;
    width: auto;
  }
}

.tab-menu {
  height: 40px;
}

.tab-item.show {
  position: relative;
  & > .dropdown-menu {
    top: 58px;
  }
}

.tab-prefer {
  height: 60px;
  background-color: rgba($white, 0.5);
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  color: rgba($black, 0.7);
  text-decoration: none;
  font-family: $font-family-sans;
  font-weight: 500;
  transition: all 0.1s ease-in-out 0s;
  .extra {
    color: #009838;
    font-weight: 400;
    display: inline-block;
    margin-left: 5px;
    font-size: 0.875rem;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: #009838;
  }
  &.active {
    font-weight: 500;
    background: $white;
    color: #009838;
    border: 1px solid rgba(207, 210, 223, 0.5);
    border-bottom: none;
    &:hover,
    &:focus {
      text-decoration: none;
      color: #009838;
    }
  }
  &.small {
    height: 52px;
    font-size: 0.875rem;
    line-height: 52px;
    .extra {
      font-size: 0.75rem;
    }
  }
  &.smaller {
    height: 40px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    line-height: 40px;
    width: auto;
  }
}

.tab-sub {
  height: 60px;
  // background-color: rgba($white,0.5);
  background-color: $white;
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  color: rgba($black, 0.7);
  text-decoration: none;
  font-family: $font-family-sans;
  font-weight: 500;
  transition: all 0.1s ease-in-out 0s;
  .extra {
    color: #4d4d4d;
    font-weight: 400;
    display: inline-block;
    margin-left: 5px;
    font-size: 0.875rem;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: #4d4d4d;
  }
  &.active {
    font-weight: 500;
    background: rgba($white, 0.5);
    color: #4d4d4d;
    border: 1px solid rgba(207, 210, 223, 0.5);
    border-bottom: none;
    &:hover,
    &:focus {
      text-decoration: none;
      color: #4d4d4d;
    }
  }
  &.small {
    height: 52px;
    font-size: 0.875rem;
    line-height: 52px;
    .extra {
      font-size: 0.75rem;
    }
  }
  &.smaller {
    height: 40px;
    line-height: 40px;
    width: auto;
    border-bottom: 1px solid;
    min-width: 266px;
  }
}

.outline-none {
  border: none;
}

.outline-none:focus {
  outline: none;
  box-shadow: none;
}
