.app-section {
	background: $white;
}

.app-listing  {
	padding: 0 16px;
}

.app-list,
.app-search {
	background: $white;
}

.app-item {
	padding: 16px;
	border-bottom: 1px solid rgba($dms-gray-100, 0.5);
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: 500ms ease all;
	&.active {
		background: $dms-green;
		.user-thumbnail {
			border: 1px solid $white;
		}
		.details {
			.main-info,
			.sub-info {
				color: $white;
			}
		}

		.action {
			.btn-ellipsis {
				.circle {
					background: $white;
				}
			}
		}
			&:hover {
		text-decoration: none;
		background: $dms-green;
		.user-thumbnail {
			border-color: $white;
		}
		.details {
			.main-info {
				color: $white;
			}
		}
	}
	}
	.user-thumbnail {
		width: 40px;
		height: 40px;
		position: relative;
		overflow: hidden;
		border-radius: 50%;
		border: 1px solid rgba($dms-gray-100, 0.5);
		transition: 500ms ease all;
		margin-right: 8px;
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
	.details {
		flex-grow: 1;
		text-decoration: none;
			transition: 500ms ease all;
		.main-info {
			font-size: 16px;
			color: $black;
			margin-bottom: 0;
			line-height: 24px;
			transition: 500ms ease all;
		}
		.sub-info {
			line-height: 16px;
			font-size: 12px;
			color: $dms-gray-300;
			margin: 0;
			transition: 500ms ease all;
		}
	}

	.actions {
		.btn-ellipsis {
			background: $dms-green;
		}
	}

	&:hover {
		text-decoration: none;
		background: rgba($dms-gray-100, 0.5);
		.user-thumbnail {
			border-color: $dms-green;
		}
		.details {
			.main-info {
				color: $dms-green;
			}
		}
	}
}