.requirements-wrapper {
  background-color: $color-requirements;
  padding: 16px;

  & .image-upload {
    display: block;
    margin: 0 auto;
    padding-bottom: 10px;
  }

  & .card-requirements {
    height: 400px;
    overflow-y: auto;
  }
}
