// ==========================================================================
// $Shame
// @see http://csswizardry.com/2013/04/shame-css/
// Thanks https://github.com/heroheman/shepherd/blob/master/sass/_shame.scss
// ==========================================================================

// ==========================================================================
// because hacks happen.
//
// be very specific about what each piece of code is doing, and
// how to better fix it later
// ==========================================================================

// Try:  $ git blame _shame.scss

// Rules:
// ---------------
//   1. If it’s a hack, it goes in _shame.scss.
//   2. Document all hacks fully:
//   3. What part of the codebase does it relate to?
//   4. Why was this needed?
//   5. How does this fix it?
//   6. How might you fix it properly, given more time?
//   7. Do not blame the developer; if they explained why they had to do it then their reasons are probably (hopefully) valid.
//   8. Try and clean _shame.scss up when you have some down time.

// Example:
// ---------------
// Nav specificity fix.

// Someone used an ID in the header code (`#header a{}`) which trumps the
// nav selectors (`.site-nav a{}`). Use !important to override it until I
// have time to refactor the header stuff.

// .site-nav a { color:#BADA55!important; }

.navbar-nav .dropdown-menu {
  position: absolute !important;
}

.rounded {
  border-radius: 15px !important;
}

.filter-dropdown {
  outline: none !important;
}

.btn-outline-light {
  border: 1px solid #eee !important;
  color: #aaa !important;
}

// UNSORTED ============
.accordion-content {
  overflow: hidden;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -ms-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

a:focus {
  outline: none !important;
}

.btn-group .dropdown-menu {
  left: -30px;
}

.dropdown-callout {
  padding: 0.25rem 0.5em;
  border-top-right-radius: 0;
  right: 0;
  left: auto;
  top: 1em;

  &:after,
  &:before {
    bottom: 100%;
    right: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right: 0 none;
  }

  &:before {
    border-bottom-color: #d2d3d6;
    border-width: 10px 12px;
    margin-right: -1px;
  }

  &:after {
    border-bottom-color: white;
    border-width: 8px 10px;
  }

  & .btn {
    box-shadow: none;
  }
}

.dropdown-callout--inverted {
  border-bottom-right-radius: 0;
  border-top-right-radius: 4px;
  bottom: 1em;
  top: auto;

  &:after,
  &:before {
    top: 100%;
  }

  &:before {
    border-top-color: #d2d3d6;
    border-bottom-color: transparent;
  }

  &:after {
    border-top-color: white;
    border-bottom-color: transparent;
  }
}

.multiple-select {
  max-height: 40vh;
  border-radius: 5px;
}

.react-select__option {
  z-index: 9999 !important;
}

.react-select__menu {
  z-index: 9999 !important;
}

.check {
  -webkit-appearance: none;
  /*hides the default checkbox*/
  height: 20px;
  width: 20px;
  transition: 0.10s;
  background-color: white;
  text-align: center;
  font-weight: 200;
  color: white;
  border-radius: 3px;
  border: 1px solid rgba(229, 231, 235, 1);
}

.check:checked {
  background-color: #0E9700;
}

.check:before {
  content: "";
}

.check:checked:before {
  content: "✔";
}

.check:hover {
  cursor: pointer;
  opacity: 0.8;
}
