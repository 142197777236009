.content-right-details {
  background-color: $card-dark-bg;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

  & .title {
    color: $color-text;
    font-size: 18px;
  }
}
