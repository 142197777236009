@import "../utilities/_mixins.scss";

.table-label {
  font-size: 13px;
  color: $color-text;
}
.table-content-list {
  font-size: 13px;
  color: $color-text;
}

.borderless td,
.borderless th {
  border: none;
}

.table {
  font-size: 14px;
  color: $gray-600;

  &-center {
    tr {
      th,
      td {
        text-align: center;
      }
    }
  }

  tr {
    th,
    td {
      vertical-align: middle;
    }
    th {
      font-size: 12px;
    }
  }

  thead {
    th {
      border-bottom-width: 0;
      border-top-width: 0;
    }
  }

  .table-header {
    background-color: $dms-gray-100;
    font-weight: bold;
    color: $dms-gray-700;

    tr > td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .table-highlight {
    background-color: #f2fdf9;
  }

  .table-text {
    &-primary {
      color: $dms-green;
      margin-bottom: 0.5em;
    }

    &-secondary {
      margin-bottom: 0.5em;
    }

    &-semibold {
      font-weight: 500;
    }

    &-bold {
      font-weight: bold;
    }

    &-center {
      text-align: center;
    }
  }

  &-interactive {
    tbody tr:hover {
      background-color: #f2fdf9;
    }
  }
}

.loadWalletDealerTransaction {
  &__container-table {
    @include containerTableBoxShadow;
  }
}

.notebookRetailer {
  &__container-table {
    @include containerTableBoxShadow;
  }
}

.notebookTransaction {
  &__container-table {
    @include containerTableBoxShadow;
  }
}

.table-sticky {
  & thead {
    & th {
      position: sticky;
      top: 0;
      background: $gray-600;
    }
  }
}

.action-table {
  flex: 0 0 3.33333%;
  max-width: 3.33333%;
  margin-right: 0.5rem;
}
