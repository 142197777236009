.mdl-card {
  min-height: 0;
  margin: 10px auto;

  &__supporting-text {
    width: 100%;
    padding: 0;
  }
}

/* Begin actual mdl-stepper css styles */

.mdl-stepper-horizontal-alternative {
  display: table;
  width: 100%;
  margin: 0 auto;

  .mdl-stepper-step {
    display: table-cell;
    position: relative;
    padding: 24px;
    font-size: 12px;

    &:first-child .mdl-stepper-bar-left,
    &:last-child .mdl-stepper-bar-right {
      display: none;
    }

    .mdl-stepper-circle {
      width: 16px;
      height: 16px;
      margin: 0 auto;
      background-color: $gray-200;
      border-radius: 50%;
      text-align: center;
      line-height: 2em;
      font-size: 9px;
      color: white;
      position: relative;
      z-index: 1;

      .mdl-stepper-number {
        color: $gray-200;
      }
    }

    .mdl-stepper-title {
      margin-top: -40px;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      display: none;
    }
  }

  .mdl-stepper-step.active-step {
    color: $gray-200;
    text-transform: uppercase;

    .mdl-stepper-circle {
      background-color: $dms-green;

      .mdl-stepper-number {
        color: $dms-green;
      }
    }

    .mdl-stepper-title {
      font-weight: 500;
      color: $dms-gray-300;
      display: block;
    }

    .mdl-stepper-bar-left {
      border-top: 1px solid $dms-green;
    }
  }

  .mdl-stepper-step.step-done {
    .mdl-stepper-circle {
      .mdl-stepper-number {
        color: $white;
      }
    }

    .mdl-stepper-bar-left,
    .mdl-stepper-bar-right {
      border-top: 1px solid $dms-green;
    }
  }

  .mdl-stepper-step.active-step.step-done .mdl-stepper-title {
    display: none;
  }

  .mdl-stepper-bar-left,
  .mdl-stepper-bar-right {
    position: absolute;
    top: 32px;
    height: 1px;
    border-top: 1px solid $gray-200;
  }

  .mdl-stepper-bar-right {
    right: 0;
    left: 50%;
  }

  .mdl-stepper-bar-left {
    left: 0;
    right: 50%;
  }
}
